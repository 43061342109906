import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/*
 * Service for adding custom icons
 * - Use svg type of icons
 * - Use template for iconName: 'app-<iconName>'
 * - Store icons in path: 'src/assets/images/iconName.svg'
 * */

@Injectable({
  providedIn: 'root',
})
export class MatIconService {
  readonly iconsPath: string = '../../assets/images/mat-icons/';

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

  registerCustomIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'app-search',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconsPath + 'Search.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-burger',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconsPath + 'Burger.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-user',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconsPath + 'User.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconsPath + 'Arrow.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-options',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconsPath + 'Options.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-form',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconsPath + 'Form.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-arrow-big',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconsPath + 'Arrow-big.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'app-google',
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.iconsPath + 'Google.svg'),
    );
  }
}
