import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageModule } from './core/modules/storage.module';
import { ToastrModule } from 'ngx-toastr';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { dbConfig } from './core/indexed-db/db.config';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './core/store/reducers/app.reducer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GeneralInterceptor } from './core/interceptors/general.interceptor';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Observable } from 'rxjs';
import { MeService } from './shared/services/me.service';
import { initializeAppFactory } from './core/utils/app_inializer';

class CustomTranslateLoader {
  constructor(
    private http: HttpClient,
    public prefix: string = '/assets/i18n/',
    public suffix: string = '.json',
  ) {}

  public getTranslation(lang: string): Observable<Object> {
    return this.http.get(`${this.prefix}${lang || 'en'}${this.suffix}`);
  }
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) =>
          new CustomTranslateLoader(http, environment.i18nPath, '.json'),
        deps: [HttpClient],
      },
      useDefaultLang: true,
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    StorageModule.forRoot(),
    ToastrModule.forRoot({ timeOut: 2500 }),
    NgxIndexedDBModule.forRoot(dbConfig),
    StoreModule.forRoot(appReducer),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    GoogleTagManagerModule.forRoot({ id: 'GTM-MPDB3Z7D' }),
    MatProgressBarModule,
  ],
  providers: [
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      }),
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [MeService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GeneralInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
  ],
})
export class AppModule {}
