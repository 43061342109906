import { initialUserState } from '../state/user.state';
import { EUserActions, UserActions } from '../actions/user.actions';

export const userReducer = (state = initialUserState, action: UserActions): any => {
  switch (action.type) {
    case EUserActions.RemoveUser: {
      return null;
    }
    case EUserActions.UpdateUser: {
      return action.user;
    }
    default: {
      return state;
    }
  }
};
