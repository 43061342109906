import { cabinetGuard } from '../../core/guards/cabinet.guard';
import { Route } from '@angular/router';

export const CABINET_ROUTE: Route = {
  path: 'cabinet',
  loadComponent: () =>
    import('src/app/modules/cabinet/cabinet.component').then((m) => m.CabinetComponent),
  canActivate: [cabinetGuard],
  children: [
    {
      path: 'qr-codes',
      loadComponent: () =>
        import('src/app/modules/cabinet/pages/cabinet-qr-codes/cabinet-qr-codes.component').then(
          (m) => m.CabinetQrCodesComponent,
        ),
    },
    {
      path: 'qr-designs',
      loadComponent: () =>
        import(
          'src/app/modules/cabinet/pages/cabinet-qr-designs/cabinet-qr-designs.component'
        ).then((m) => m.CabinetQrDesignsComponent),
    },
    {
      path: 'qr-design/:id',
      loadComponent: () =>
        import('src/app/modules/cabinet/pages/cabinet-qr-design/cabinet-qr-design.component').then(
          (m) => m.CabinetQrDesignComponent,
        ),
    },
    {
      path: 'qr-code/:id',
      loadComponent: () =>
        import('src/app/modules/cabinet/pages/cabinet-qr-code/cabinet-qr-code.component').then(
          (m) => m.CabinetQrCodeComponent,
        ),
    },
    {
      path: 'settings',
      loadComponent: () =>
        import('src/app/modules/cabinet/pages/settings/settings.component').then(
          (m) => m.SettingsComponent,
        ),
    },
    {
      path: 'pricing',
      loadComponent: () =>
        import('src/app/modules/cabinet/pages/tariff/tariff.component').then(
          (m) => m.TariffComponent,
        ),
    },
    // {
    //   path: 'notifications',
    //   loadComponent: () =>
    //     import('src/app/modules/cabinet/pages/notifications/notifications.component').then(
    //       (m) => m.NotificationsComponent,
    //     ),
    // },
    // {
    //   path: 'qr-designs',
    //   loadComponent: () =>
    //     import('src/app/modules/cabinet/pages/cabinet-qr-codes/cabinet-qr-codes.component').then(
    //       (m) => m.CabinetQrCodesComponent,
    //     ),
    // },
    // {
    //   path: 'scans',
    //   loadComponent: () =>
    //     import('src/app/modules/cabinet/pages/cabinet-qr-codes/cabinet-qr-codes.component').then(
    //       (m) => m.CabinetQrCodesComponent,
    //     ),
    // },
    {
      path: '',
      redirectTo: 'qr-codes',
      pathMatch: 'full',
    },
  ],
};
