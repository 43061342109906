import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { LOCALES } from './core/locales';
import { CABINET_ROUTE } from './modules/cabinet/routes';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('src/app/modules/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('src/app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  CABINET_ROUTE,
  {
    path: 'r/:shortUrl',
    loadComponent: () =>
      import('src/app/modules/qr-short-link/qr-short-link.component').then(
        (m) => m.QrShortLinkComponent,
      ),
  },
  {
    path: '404',
    loadComponent: () =>
      import('src/app/shared/components/no-page/no-page.component').then((m) => m.NoPageComponent),
  },
  {
    path: '**',
    redirectTo: '404',
    pathMatch: 'full',
  },
];

export function createTranslateLoader(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
) {
  return new ManualParserLoader(translate, location, settings, LOCALES, 'ROUTES.');
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      enableViewTransitions: true,
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: createTranslateLoader,
        deps: [TranslateService, Location, LocalizeRouterSettings],
      },
      initialNavigation: false,
      alwaysSetPrefix: false,
      defaultLangFunction: () => 'en',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
