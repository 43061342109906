import { IUser } from '../../interfaces/user.interface';

export const initialUserState: IUser = {
  id: -1,
  username: '',
  email: '',
  provider: '',
  confirmed: false,
  blocked: false,
  createdAt: '',
  updatedAt: '',
  uuid: '',
  email_confirmed: false,
};
