import { ModuleWithProviders, NgModule } from '@angular/core';
import { BaseMemoryStorage } from '../storages/base-memory.storage';
import { LocalStorage } from '../interfaces/local-storage.interface';
import { StorageOptions } from '../interfaces/storage.interface';
import { BaseLocalStorage } from '../storages/base-local.storage';
import { MemoryStorage } from '../interfaces/memory-storage.interface';

@NgModule()
export class StorageModule {
  static forRoot(options: Partial<StorageOptions> = {}): ModuleWithProviders<StorageModule> {
    return {
      ngModule: StorageModule,
      providers: [
        {
          provide: LocalStorage,
          useClass: options.localStorage || BaseLocalStorage,
        },
        {
          provide: MemoryStorage,
          useClass: options.memoryStorage || BaseMemoryStorage,
        },
      ],
    };
  }
}
