import { catchError, EMPTY, Observable } from 'rxjs';
import { IMe, MeService } from '../../shared/services/me.service';

export function initializeAppFactory(meService: MeService): () => Observable<IMe> {
  return () => meService.initializeMe().pipe(catchError(() => EMPTY));

  // if (isPlatformServer(platformId)) {
  //   return () => EMPTY;
  // } else {
  //   return () => {
  //     const agent = window_?.navigator?.userAgent || '';
  //
  //     const botUserAgentsArray = [
  //       'googlebot',
  //       'bingbot',
  //       'linkedinbot',
  //       'mediapartners-google',
  //       'lighthouse',
  //       'insights',
  //     ];
  //
  //     let isBotUserAgent = false;
  //     for (let j = 0; j < botUserAgentsArray.length; j++) {
  //       if (agent.toLowerCase().indexOf(botUserAgentsArray[j].toLowerCase()) !== -1) {
  //         isBotUserAgent = true;
  //         break;
  //       }
  //     }
  //
  //     if (isBotUserAgent) {
  //       return EMPTY;
  //     }
  //
  //     let browserLang =
  //       localStorage_.getItem('manuallyUsedLang') ||
  //       window_?.navigator?.language?.split('-')[0].toLowerCase();
  //     browserLang = LOCALES.includes(browserLang) ? browserLang : 'en';
  //
  //     let currentUrl = window_.location.pathname.split('?')[0];
  //
  //     if (['/uk', '/en', '/'].includes(currentUrl)) {
  //       currentUrl = '';
  //     } else {
  //       currentUrl = currentUrl.replace('/en/', '').replace('/uk/', '');
  //     }
  //
  //     if (browserLang !== translateService.currentLang) {
  //       if (LOCALES.includes(browserLang)) {
  //         return translateService.use(browserLang).pipe(
  //           delay(200),
  //           tap(() => {
  //             if (browserLang === 'en') {
  //               router.navigate([`${currentUrl}`], {
  //                 queryParamsHandling: 'merge',
  //               });
  //             } else {
  //               router.navigate([`/${browserLang}/${currentUrl}`], {
  //                 queryParamsHandling: 'merge',
  //               });
  //             }
  //           }),
  //         );
  //       }
  //
  //       return translateService.use('en').pipe(
  //         delay(200),
  //         tap(() => {
  //           router.navigate([`${currentUrl}`], {
  //             queryParamsHandling: 'merge',
  //           });
  //         }),
  //       );
  //     } else if (browserLang === 'en') {
  //       return translateService.use('en').pipe(
  //         delay(200),
  //         tap(() => {
  //           router.navigate([`${currentUrl}`], {
  //             queryParamsHandling: 'merge',
  //           });
  //         }),
  //       );
  //     }
  //
  //     return EMPTY;
  //     // if (!localStorage.getItem('token')) {
  //     //   return authService.registerAnonym().pipe(
  //     //     tap((res) => {
  //     //       localStorage.setItem('token', res.jwt);
  //     //       authService.getUserInfo();
  //     //     }),
  //     //   );
  //     // } else {
  //     //   authService.getUserInfo();
  //     //   return EMPTY;
  //     // }
  //     /*
  //     return httpClient.get('https://iplist.cc/api/').pipe(
  //       switchMap((res: any) => {
  //         return httpClient.post(environment.apiHostName + 'info/ip', { ip: res.ip }).pipe(
  //           switchMap((res) => {
  //
  //           }),
  //         );
  //       }),
  //     );
  //     */
  //   };
  // }
}
