import { Component, inject } from '@angular/core';
import { MatIconService } from './core/utils/mat-icon/mat-icon.service';
import { filter, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { NotFoundService } from './shared/services/not-found.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: false,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  private document = inject(DOCUMENT);
  private router = inject(Router);
  private notFoundService = inject(NotFoundService);
  private matIconService = inject(MatIconService);
  private translateService = inject(TranslateService);

  constructor() {
    this.matIconService.registerCustomIcons();
    this.document.documentElement.setAttribute('lang', this.translateService.currentLang);

    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        takeUntilDestroyed(),
      )
      .subscribe((event: any) => {
        if (this.router.url !== event.url && this.router.url === '/404' && event.url) {
          this.notFoundService.emitTelegramBot404Error(event.url).pipe(take(1)).subscribe();
        }
      });
  }
}
