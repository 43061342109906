import { DBConfig } from 'ngx-indexed-db';

export const dbConfig: DBConfig = {
  name: 'Scanner DB',
  version: 2,
  objectStoresMeta: [
    {
      store: 'list',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'title', keypath: 'title', options: { unique: true } },
        { name: 'columns', keypath: 'columns', options: { unique: false } },
        { name: 'settings', keypath: 'settings', options: { unique: false } },
        { name: 'content', keypath: 'content', options: { unique: false } },
      ],
    },
    {
      store: 'files',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [{ name: 'file', keypath: 'file', options: { unique: true } }],
    },
  ],
};
