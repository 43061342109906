import { initialScanTablesState } from '../state/scan-tables.state';
import { EScanTablesActions, ScanTableActions } from '../actions/scan-tables.actions';
import { IScanTableResponse } from '../../../interfaces/scan/scan-table.interface';

export const scanTablesReducer = (
  state = initialScanTablesState,
  action: ScanTableActions,
): any => {
  switch (action.type) {
    case EScanTablesActions.AddTable: {
      return addTable(state, action.table);
    }
    case EScanTablesActions.RemoveTable: {
      return state.filter((table) => table.id !== action.id);
    }
    case EScanTablesActions.UpdateTable: {
      return updateTable(state, action.table);
    }
    case EScanTablesActions.RemoveAllTables: {
      return [];
    }
    default: {
      return state;
    }
  }
};

function updateTable(state: IScanTableResponse[], table: IScanTableResponse): IScanTableResponse[] {
  const id = state.findIndex((stateTable) => stateTable.id === table.id);
  if (id !== -1) {
    let tempState = new Array(...state);
    tempState[id] = table;
    return tempState;
  } else {
    return [...state, table];
  }
}

function addTable(state: IScanTableResponse[], table: IScanTableResponse): IScanTableResponse[] {
  if (state.findIndex((stateTable) => stateTable.id === table.id) === -1) {
    return [...state, table];
  }
  return state;
}
