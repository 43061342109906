import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, finalize, Observable, switchMap, take, throwError } from 'rxjs';
import { LocalStorage } from '../interfaces/local-storage.interface';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';
import { LOCATION } from '../injection-tokens/location.token';
import { environment } from '../../../environments/environment';

@Injectable()
export class GeneralInterceptor implements HttpInterceptor {
  private location = inject(LOCATION);
  private AUTH_HEADER = 'Authorization';

  constructor(
    private localStorage: LocalStorage,
    private http: HttpClient,
    private router: Router,
    private localizeService: LocalizeRouterService,
  ) {}

  isRefreshing: boolean = false;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type')) {
      if (!req.url.includes('users/images')) {
        req = req.clone({
          headers: req.headers.set('Content-Type', 'application/json'),
        });
      }
    }

    if (!req.url.includes('auth/refresh')) {
      req = this.addAuthenticationToken(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401 && !this.isRefreshing) {
          this.isRefreshing = true;
          const refresh_token = this.localStorage.getItem('refresh_token');

          if (refresh_token) {
            return this.on401ErrorEmailAndPassword(req, next, refresh_token);
          } else {
            // if no refresh_token, try cookie (google auth)

            return this.on401ErrorGoogleAuth(req, next);
          }
        } else {
          return throwError(error);
        }
      }),
    );
  }

  on401ErrorGoogleAuth(req: any, next: any): Observable<any> {
    return this.http.post(environment.apiHostName + 'auth/refresh', {}).pipe(
      catchError((err) => {
        this.localStorage.removeItem('access_token');
        this.localStorage.removeItem('refresh_token');
        this.localStorage.removeItem('google-auth');

        this.isRefreshing = false;
        return throwError(err);
      }),
      switchMap(() => {
        this.isRefreshing = false;

        this.location.reload();

        return next.handle(this.addAuthenticationToken(req));
      }),
      finalize(() => (this.isRefreshing = false)),
      take(1),
    );
  }

  on401ErrorEmailAndPassword(req: any, next: any, refresh_token: any): Observable<any> {
    return this.http.post(environment.apiHostName + 'auth/refresh', { refresh_token }).pipe(
      catchError((err) => {
        this.localStorage.removeItem('access_token');
        this.localStorage.removeItem('refresh_token');
        this.localStorage.removeItem('google-auth');

        this.isRefreshing = false;
        return throwError(err);
      }),
      switchMap((res: any) => {
        this.isRefreshing = false;
        if (res.data.access_token) {
          this.localStorage.setItem('access_token', res.data.access_token);
        }
        if (res.data.refresh_token) {
          this.localStorage.setItem('refresh_token', res.data.refresh_token);
        }

        this.location.reload();

        return next.handle(this.addAuthenticationToken(req));
      }),
      finalize(() => (this.isRefreshing = false)),
      take(1),
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    const token = this.localStorage.getItem('access_token');
    if (!token) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + token),
    });
  }
}
