import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { MeService } from '../../shared/services/me.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

export const cabinetGuard: CanActivateFn = () => {
  const isAuthorized = inject(MeService).isAuthorized;
  const router = inject(Router);
  const localize = inject(LocalizeRouterService);

  if (isAuthorized) {
    return true;
  } else {
    router.navigate([localize.translateRoute('/')]);
    return false;
  }
};
